export const routes = {
  home: "/",
  contact: "/contact",
  budgetCalculator: "/budget-calculator",
  about: "/about",
  intermediaries: "/intermediaries",
  keyWorkers: "/key-workers",
  privacy: "/privacy",
  termsOfUse: "/terms-of-use",
  surveyAndValuationFee: "/survey-and-valuation-fee",
  faqs: "/",
  howitworks: "/how-it-works",
  press: "/press",
  bipPdf: "/bip-pdf",
  quoteOfferPdf: "/quote-offer-pdf",
  shareBudget: "/share-budget",
  blog: "/blog",
  landlords: {
    root: "/landlords",
    register: "/landlords/register",
    dealCalculator: "/landlords/deal-calculator",
    aboutUs: "/landlords/about-us",
    howItWorks: "/landlords/how-it-works",
    articles: "/landlords/articles",
  },
  now: {
    root: "/now",
    howItWorks: "/now/how-it-works",
    faqs: "/now/faqs",
    searchHomes: "/now/property-search",
    calculator: "/now/#calculator",
  },
  customerReviews: "/customer-reviews",
  application: {
    root: "/application",
    personalDetails: "/application/personal-details",
    income: "/application/income",
    expenditure: "/application/expenditure",
    qualifyingCriteria: "/application/qualifying-criteria",
    property: "/application/property",
    setPassword: "/application/set-password",
    incomeVerification: "/application/income-verification",
    contactUs: "/application/contact-us",
  },
  dashboard: {
    root: "/dashboard",
    login: "/dashboard/login",
    forgotPassword: "/dashboard/forgot-password",
    newPassword: "/dashboard/forgot-password/new",
    qualifyingCriteria: "/dashboard/qualifying-criteria",
    identityVerification: "/dashboard/identity-verification",
    incomeVerification: "/dashboard/income-verification",
    documents: "/dashboard/documents",
    referral: "/dashboard/referral",
    faq: "/dashboard/faq",
    contact: "/dashboard/contact",
    preferredPropertyDetails: "/dashboard/preferred-property-details",
    manageAccount: "/dashboard/manage-account",
    secondaryApplicant: {
      root: "/dashboard/secondary-applicant",
      personalDetails: "/dashboard/secondary-applicant/personal-details",
      income: "/dashboard/secondary-applicant/income",
      expenditure: "/dashboard/secondary-applicant/expenditure",
      qualifyingCriteria: "/dashboard/secondary-applicant/qualifying-criteria",
      contactUs: "/dashboard/secondary-applicant/contact-us",
    },
    propertyOverview: {
      root: "/dashboard/property-overview/",
      address: "/dashboard/property-overview/address",
      agent: "/dashboard/property-overview/estate-agent",
    },
    propertyDetails: {
      root: "/dashboard/property-details/",
      propertyType: "/dashboard/property-details/property-type",
      leaseType: "/dashboard/property-details/lease-type",
      propertyInformation: "/dashboard/property-details/property-information",
    },
    personalDetails: {
      root: "/dashboard/personal-details/",
      about: "/dashboard/personal-details/about",
      addressHistory: "/dashboard/personal-details/address-history",
    },
    financialDetails: {
      primary: "/dashboard/financial-details/primary",
      other: {
        root: "/dashboard/financial-details/other",
        employer: "/dashboard/financial-details/other/employer",
        soleTrader: "/dashboard/financial-details/other/sole-trader",
        ltd: "/dashboard/financial-details/other/ltd",
        benefits: "/dashboard/financial-details/other/benefits",
        pension: "/dashboard/financial-details/other/pension",
        other: "/dashboard/financial-details/other/other",
      },
    },
    expenditure: {
      loansAndMortgages: "/dashboard/expenditure/loans-and-mortgages",
      creditAndStoreCards: "/dashboard/expenditure/credit-and-store-cards",
      dependants: "/dashboard/expenditure/dependants",
    },
    yourOffer: "/dashboard/your-offer",
  },
};
