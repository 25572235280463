import Image from "next/image";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

import { Props } from "./types";

const variantClasses = {
  orange: "border-2 text-brown-400 border-brown-600",
  orangeFull: "border-2 text-customWhite border-brown-400 bg-brown-400",
  submit:
    "border-blue-500 text-customWhite bg-blue-500 disabled:bg-grey-600 disabled:text-grey-300 disabled:border-grey-600",
  wait: "bg-brown-700 disabled:bg-brown-700 border-none",
  grey: "text-customWhite bg-grey-600 border-grey-600",
  darkGrey: "text-customWhite bg-grey-800 border-grey-800",
  smallButton: "text-customWhite bg-grey-800 border-grey-800 !h-8 px-1",
  landingDenim:
    "border-landing-denim text-customWhite bg-landing-denim disabled:bg-grey-600 disabled:text-grey-300 disabled:border-grey-600",
  noBackgroundButtonGrey:
    "border-grey-600 text-grey-600 disabled:border disabled:text-grey-300 disabled:border-grey-300",
};

const DashboardButton: FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  prefix,
  suffix,
  variant,
  disabled,
  className,
  spinPrefix,
  ...props
}) => {
  let colorDependsOnVariant = variantClasses[
    variant as "orange" | "orangeFull" | "submit" | "wait" | "grey" | "smallButton"
  ] as string;

  const classes = twMerge(`
  flex justify-center items-center px-5 text-md md:text-lg font-bold border rounded-lg text-grey-100 h-12 md:h-14 border-grey-400 leading-snug disabled:border-2 disabled:text-grey-650 disabled:border-grey-650
  ${className ?? ""}
  ${colorDependsOnVariant ?? ""}
`);
  return (
    <button className={classes} disabled={disabled} {...props}>
      {prefix && (
        <div
          className={`flex justify-center w-8 h-8  ${spinPrefix ? "animate-spin" : ""}  ${
            variant !== "smallButton" ? "mr-2" : "mr-1"
          }`}
        >
          <Image
            src={prefix}
            height={variant === "landingDenim" || variant === "noBackgroundButtonGrey" ? 32 : 24}
            width={variant === "landingDenim" || variant === "noBackgroundButtonGrey" ? 32 : 24}
            alt="icon"
          />
        </div>
      )}
      <span
        className={`${variant === "wait" ? `text-white` : ""} ${
          variant === "smallButton" ? `text-base truncate leading-5` : ""
        }`}
      >
        {children}
      </span>
      {suffix && (
        <div className={`flex justify-center w-8 h-8 ml-2 ${variant === "smallButton" && `ml-1`}`}>
          <Image src={suffix} height={24} width={24} alt="icon" />
        </div>
      )}
    </button>
  );
};

export default DashboardButton;
