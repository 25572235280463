import React from "react";
import RightArrow from "@/public/icons/hiw-right-arrow.svg";
import Link from "next/link";
import Image from "next/image";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import { Props } from "./types";

export const ARTICLES_DEFAULT_POPULATE = ["categories", "cover", "author", "author.avatar"];
const SingleArticle = ({ article, baseUrl }: Props) => {
  const { width } = useWindowSize();

  function formatISODateToReadableDate(inputDateString: string) {
    const date = new Date(inputDateString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();

    const formattedDateString = `${monthNames[monthIndex]} ${day}, ${year}`;

    return formattedDateString;
  }
  return (
    <a href={baseUrl + "/" + article.attributes.slug}>
      <div className="group">
        <div
          className="flex w-full group-hover:opacity-80 bg-landing-gray relative rounded-tl-[15px] rounded-tr-[15px] cursor-pointer"
          style={{ paddingTop: "57.5%" }}
        >
          <Image
            src={article?.attributes?.cover?.data?.attributes?.formats?.large?.url}
            alt={article.attributes.title}
            layout="fill"
            objectFit="contain"
            className="rounded-tl-[15px] rounded-tr-[15px]"
          />
        </div>
        <div className="bg-landing-gray  rounded-bl-[15px] rounded-br-[15px] px-6 py-4">
          <div className="font-serif text-[32px] leading-10 h-20 min-h-20 mb-5 mt-3.5 line-clamp-2">
            {article.attributes.title}
          </div>
          <div className="mb-5 text-lg leading-[23px] font-semibold h-[2.875rem] min-h-[2.875rem] line-clamp-2">
            <p>{article.attributes.subTitle}</p>
          </div>
          <div className="h-[5.75rem] min-h-[5.75rem] text-base leading-[23px] mb-5 line-clamp-4">
            {article.attributes.description}
          </div>
          <div
            className={`flex  ${
              width < Screen.Mobile_375 ? "flex-col items-left" : "flex-row items-center"
            } justify-center md:justify-between align-middle  mb-5 w-full`}
          >
            <div
              className={`w-full flex flex-row justify-between align-middle items-center ${
                width < Screen.Mobile_375 && "mb-5"
              }`}
            >
              <div className="flex flex-row items-center">
                <div
                  className={`relative min-w-[63px] min-h-[63px] w-[63px] h-[63px] mr-2 md:mr-4 items-center align-middle`}
                >
                  <Image
                    src={
                      article?.attributes?.author?.data?.attributes?.avatar?.data.attributes.formats
                        ?.thumbnail?.url || "/icons/avatar.svg"
                    }
                    alt={article.attributes.title || "Author"}
                    width={"100%"}
                    height={"100%"}
                    layout="responsive"
                    objectFit="cover"
                    priority
                    className="rounded-full"
                  />
                </div>

                <div className="w-auto  font-semibold line-clamp-2 mr-4">
                  {article?.attributes?.author?.data?.attributes.name}{" "}
                </div>
              </div>
              <div
                className={`w-auto flex flex-col text-right items-end ${
                  width < Screen.Mobile_375 && "hidden"
                }`}
              >
                <div className="font-semibold text-landing-blue/60 w-max md:w-auto">
                  Published on
                </div>
                <div className="font-semibold w-max">
                  {formatISODateToReadableDate(article.attributes.addedOn)}
                </div>
              </div>
            </div>
            <div
              className={` w-full flex-col text-right items-end ${
                width < Screen.Mobile_375 ? "flex" : "hidden"
              }`}
            >
              <div className="font-semibold text-landing-blue/60 w-max">Published on</div>
              <div className="font-semibold w-max">
                {formatISODateToReadableDate(article.attributes.addedOn)}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <a
              className="flex flex-row align-middle items-center text-landing-orange font-bold"
              href={baseUrl + "/" + article.attributes.slug}
            >
              Read full article <RightArrow className="ml-3" />
            </a>
            <div className="font-semibold text-landing-blue/60 flex text-right w-max">
              {article.attributes.readingTime} min read
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default SingleArticle;
